<template>
  <div class="eight">
    <div class="title">
      <p class="text">应用场景</p>
      <img src="../assets/img/cross.png" alt="" class="cross">
    </div>
    <div class="list">
      <ul class="ul">
        <li v-for="(item, index) in list" :key="index" style="margin: 30px 13.5px 0;">

          <img :src="item.src" />


          <!-- <p class="name_text">
            {{ item.title }}
          </p> -->

        </li>
      </ul>
      <img class="more" src="../assets/img/more.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "moduleEight",
  data() {
    return {
      list: [
        {
          src: 'https://gw-resources.uhan.net.cn/%E6%99%BA%E6%85%A7%E7%BB%BC%E5%90%88%E4%BD%93.png',
          title: '智慧综合体',
          translate: 'Complex'
        },
        {
          src: 'https://gw-resources.uhan.net.cn/%E6%99%BA%E6%85%A7%E6%94%BF%E5%8A%A1.png',
          title: '智慧政务',
          translate: 'Smart government'
        },
        {
          src: 'https://gw-resources.uhan.net.cn/%E6%99%BA%E6%85%A7%E6%96%87%E6%97%85.png',
          title: '智慧文旅',
          translate: 'Culture and Tourism'
        },
        {
          src: 'https://gw-resources.uhan.net.cn/%E6%99%BA%E6%85%A7%E8%90%A5%E4%B8%9A%E5%8E%85.png',
          title: '智慧营业厅',
          translate: 'Business hall'
        },
        {
          src: 'https://gw-resources.uhan.net.cn/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA.png',
          title: '智慧党建',
          translate: 'Party Building'
        },
        {
          src: 'https://gw-resources.uhan.net.cn/%E6%99%BA%E6%85%A7%E5%B1%95%E5%8E%85.png',
          title: '智慧展厅',
          translate: 'Exhibition hall'
        },
      ],
    };
  },
  computed: {
  },

  created() {
    // this.getList()
  },
  mounted() {

  },
  methods: {
    async getList() {
      try {
        const response = await this.$axios.get("/settings/homePage")
        console.log(response.data);
        this.list = response.data.rows


      } catch (error) {
        console.error("请求错误:", error)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.eight {
  width: 100%;
  padding-top: 6%;

}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    display: inline-block;
    font-size: 42.33px;
    font-weight: 400;
    letter-spacing: 6px;
    font-family: typeface !important;
    background: linear-gradient(90deg,
        rgba(96, 252, 148, 1) 0%,
        rgba(77, 255, 201, 1) 6.02%,
        rgba(0, 204, 255, 1) 74.71%,
        rgba(71, 175, 255, 1) 100%);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    vertical-align: top;
  }

  img {
    width: 340px;
  }
}

.list {
  position: relative;
  img {
    transition: transform 0.3s;
  }

  img:hover {
    // transform: scale(1.1);
  }

  padding: 0 10vw;
}

.ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 25vw;
    box-sizing: border-box;
    position: relative;

    img {
      width: 100%;
      height: 28vh;
      border-radius: 14.43px
    }

    .name_text {
      font-family: 'PingFang';
      position: absolute;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.9px;
      line-height: 27.06px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: middle;
      left: 13.53px;
      bottom: 20.6px;
    }
  }
}
.more{
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  margin-top: 2%;
  display: block;
  cursor: pointer;
}
</style>