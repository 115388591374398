import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { light: 'home' }
  },
  {
    path: '/human/:id/:top',
    name: 'human',
    component: () => import('../views/AboutView.vue'),
    meta: { light: 'human' }
  },
  {
    path: '/special/:val',
    name: 'special',
    component: () => import('../views/Analysis.vue'),
    meta: { light: 'special' }
  },
  {
    path: '/solution/:id/:top',
    name: 'solution',
    component: () => import('../views/AboutView.vue'),
    meta: { light: 'solution' }
  },
  {
    path: '/case/:val',
    name: 'case',
    component: () => import('../views/Analysis.vue'),
    meta: { light: 'case' }
  },
  {
    path: '/aboutus/:top',
    name: 'about',
    component: () => import('../views/aboutus.vue'),
    meta: { light: 'about' }
  },
  {
    path: '/information/:top',
    name: 'news',
    component: () => import('../views/information.vue'),
    meta: { light: 'news' }
  },
  {
    path: '/informationcase/:val/:id',
    name: 'informationcase',
    component: () => import('../views/Analysissub.vue'),
    meta: { light: 'news' }
  },

  {
    path: '/analysissubcase/:val/:id',
    name: 'analysissubcase',
    component: () => import('../views/Analysissub.vue'),
    meta: { light: 'case' }
  },
  {
    path: '/analysissubspecial/:val/:id',
    name: 'analysissubspecial',
    component: () => import('../views/Analysissub.vue'),
    meta: { light: 'special' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
