<template>
  <div class="three">
    <div class="title">
      <p class="text">硬件产品全家福</p>
      <img src="../assets/img/cross.png" alt="" class="cross">
    </div>
    <div class="list">
      <div class="swiper">
        <img class="swiper-button-prev btn" src="../assets/img/left.png" alt="">
        <div ref="swiperContainer" class="swiper-container-three" style="width: 70vw;overflow: hidden;">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide " v-for="(image, index) in images" :key="index">
            <div style="height: 100%;vertical-align: bottom; ">
              <img style="width: 280px; " :src="require(`@/assets/digital/黑中/${image}黑.png`)"
                :alt="`Image ${index + 1}`" />
            </div>
            
          </div> -->
            <div class="swiper-slide " v-for="(image, index) in humanList" :key="index" :data-a="index">
              <div
                style="height: 26vw;display: flex; align-items: center;flex-direction: column;justify-content: flex-end ">
                <img
                  style=" display: inline-block; -webkit-box-reflect:below 10px linear-gradient(transparent,■#000); box-reflect:below 10px linear-gradient(transparent,■#000);"
                  :data-a="index" class="images" @click="send" :src="currentImageSrc(index)"
                  :alt="`Image ${index + 1}`" />
              </div>

            </div>
          </div>
        </div>
        <img class="swiper-button-next btn" src="../assets/img/right.png" alt="">
      </div>

      <div class="btn">
        <div class="turn-left">
          <img @click="change('left')" v-if="hover == 'left'" src="../assets/img/按钮组/select_left.png" alt="">
          <img @click="change('left')" v-else src="../assets/img/按钮组/normal_left.png" alt="">
        </div>
        <div class="turn-middle">
          <img @click="change('middle')" v-if="hover == 'middle'" src="../assets/img/按钮组/select_middle.png" alt="">
          <img @click="change('middle')" v-else src="../assets/img/按钮组/normal_middle.png" alt="">
        </div>
        <div class="turn-right">
          <img @click="change('right')" v-if="hover == 'right'" src="../assets/img/按钮组/select_right.png" alt="">
          <img @click="change('right')" v-else src="../assets/img/按钮组/normal_right.png" alt="">
        </div>
      </div>
      <div class="tips">
        <p v-show="tips">点击切换视角</p>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/css/font/index.css'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
export default {
  name: "moduleThree",
  data() {
    return {
      hoverImage: null, // 当前悬停的图片索引
      inputMessage: '',//向主页传递的参数
      humanList: [
        {
          bl: 'https://gw-resources.uhan.net.cn/43bl.png',
          bm: 'https://gw-resources.uhan.net.cn/43bm.png',
          br: 'https://gw-resources.uhan.net.cn/43br.png',
          wl: 'https://gw-resources.uhan.net.cn/43wl.png',
          wm: 'https://gw-resources.uhan.net.cn/43wm.png',
          wr: 'https://gw-resources.uhan.net.cn/43wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/65bl.png',
          bm: 'https://gw-resources.uhan.net.cn/65bm.png',
          br: 'https://gw-resources.uhan.net.cn/65br.png',
          wl: 'https://gw-resources.uhan.net.cn/65wl.png',
          wm: 'https://gw-resources.uhan.net.cn/65wm.png',
          wr: 'https://gw-resources.uhan.net.cn/65wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/75bl.png',
          bm: 'https://gw-resources.uhan.net.cn/75bm.png',
          br: 'https://gw-resources.uhan.net.cn/75br.png',
          wl: 'https://gw-resources.uhan.net.cn/75wl.png',
          wm: 'https://gw-resources.uhan.net.cn/75wm.png',
          wr: 'https://gw-resources.uhan.net.cn/75wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/71bl.png',
          bm: 'https://gw-resources.uhan.net.cn/71bm.png',
          br: 'https://gw-resources.uhan.net.cn/71br.png',
          wl: 'https://gw-resources.uhan.net.cn/71wl.png',
          wm: 'https://gw-resources.uhan.net.cn/71wm.png',
          wr: 'https://gw-resources.uhan.net.cn/71wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/55bl.png',
          bm: 'https://gw-resources.uhan.net.cn/55bm.png',
          br: 'https://gw-resources.uhan.net.cn/55br.png',
          wl: 'https://gw-resources.uhan.net.cn/55wl.png',
          wm: 'https://gw-resources.uhan.net.cn/55wm.png',
          wr: 'https://gw-resources.uhan.net.cn/55wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/75_bl.png',
          bm: 'https://gw-resources.uhan.net.cn/75_bm.png',
          br: 'https://gw-resources.uhan.net.cn/75_br.png',
          wl: 'https://gw-resources.uhan.net.cn/75_wl.png',
          wm: 'https://gw-resources.uhan.net.cn/75_wm.png',
          wr: 'https://gw-resources.uhan.net.cn/75_wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/49bl.png',
          bm: 'https://gw-resources.uhan.net.cn/49bm.png',
          br: 'https://gw-resources.uhan.net.cn/49br.png',
          wl: 'https://gw-resources.uhan.net.cn/49wl.png',
          wm: 'https://gw-resources.uhan.net.cn/49wm.png',
          wr: 'https://gw-resources.uhan.net.cn/49wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/86bl.png',
          bm: 'https://gw-resources.uhan.net.cn/86bm.png',
          br: 'https://gw-resources.uhan.net.cn/86br.png',
          wl: 'https://gw-resources.uhan.net.cn/86wl.png',
          wm: 'https://gw-resources.uhan.net.cn/86wm.png',
          wr: 'https://gw-resources.uhan.net.cn/86wr.png',
        },
        {
          bl: 'https://gw-resources.uhan.net.cn/32bl.png',
          bm: 'https://gw-resources.uhan.net.cn/32bm.png',
          br: 'https://gw-resources.uhan.net.cn/32br.png',
          wl: 'https://gw-resources.uhan.net.cn/32wl.png',
          wm: 'https://gw-resources.uhan.net.cn/32wm.png',
          wr: 'https://gw-resources.uhan.net.cn/32wr.png',
        },



      ],
      // images: ['43中_', '49中_', '75中_', '55中_', '55中多模态_', '65中_', '65中多模态_', '71中_', '71中多模态_', '75中多模态_']
      images: ['43中_', '49中_', '75中_', '55中_', '55中多模态_', '65中_', '65中多模态_', '71中_', '71中多模态_', '75中多模态_'],
      hover: 'middle',
      tips: true,
    };
  },
  computed: {
  },

  created() {

  },
  mounted() {
    // 延迟执行以确保 DOM 加载完毕
    this.$nextTick(() => {
      const swiperThree = new Swiper(".swiper-container-three", {
        slidesPerView: 5,
        spaceBetween: 0,
        centeredSlides: true,
        mousewheel: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        initialSlide: Math.floor(5 / 2), // 将初始滑块设置为中间
        on: {
          slideChange: () => {
            // const currentIndex = swiperThree.realIndex;
            // console.log('currentIndex',currentIndex);
            // const totalSlides = swiperThree.slides.length;

            // // 如果滑动到第一个或最后一个滑块，允许外部 Swiper 继续滑动
            // if (currentIndex === 0 || currentIndex === totalSlides -1) {
            //   // this.mySwiper.allowSlideNext = true;
            //   // this.mySwiper.allowSlidePrev = true;
            //   if (currentIndex === 0) {
            //     // this.mySwiper.slidePrev(); // 外部 Swiper 滑动到上一页
            //     this.inputMessage = 'up'
            //     this.$emit('update', this.inputMessage);
            //   } else if (currentIndex === totalSlides-1 ) {
            //     // this.mySwiper.slideNext(); // 外部 Swiper 滑动到下一页
            //     this.inputMessage = 'down'
            //     this.$emit('update', this.inputMessage);
            //   }
            // }
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
      // 将 swiperThree 存储为组件实例的一个属性
      this.swiperThreeInstance = swiperThree;
      // 监听全局鼠标滚轮事件
      // window.addEventListener('wheel', this.handleGlobalWheel,{ passive: false });

      if (this.$refs.swiperContainer) {
        this.$refs.swiperContainer.addEventListener('mouseover', this.handleMouseOver);
        this.$refs.swiperContainer.addEventListener('mouseleave', this.handleMouseLeave);
      }
    });
  },
  methods: {
    handleGlobalWheel(event) {
      // 阻止默认滚动行为
      event.preventDefault();

      // 根据滚轮方向进行滑动
      if (event.deltaY > 0) {
        this.swiperThreeInstance.slideNext();
      } else {
        this.swiperThreeInstance.slidePrev();
      }
    },

    currentImageSrc(index) {
      // 检查 this.$refs.swiperContainer 是否存在
      if (!this.$refs.swiperContainer) {
        return this.humanList[index].bm;
      }

      const activeSlide = this.$refs.swiperContainer.querySelector('.swiper-slide-active');
      if (!activeSlide) {
        return this.humanList[index].bm;
      }
      const activeIndex = parseInt(activeSlide.getAttribute('data-a'), 10);

      if (index === activeIndex) {
        if (this.hover === 'left') {
          return this.hoverImage === index ? this.humanList[index].wl : this.humanList[index].bl;
        } else if (this.hover === 'right') {
          return this.hoverImage === index ? this.humanList[index].wr : this.humanList[index].br;
        }
      }

      return this.hoverImage === index ? this.humanList[index].wm : this.humanList[index].bm;
    },
    change(val) {
      this.tips = false;
      this.hover = val;
      const activeSlide = this.$refs.swiperContainer.querySelector('.swiper-slide-active');
      const index = parseInt(activeSlide.getAttribute('data-a'), 10);
      // 这里可以触发重新渲染
      this.$forceUpdate();
    },
    send() {
      console.log('1111');

    },
    handleMouseOver(event) {
      this.$forceUpdate();

      const target = event.target;
      // console.log('Mouse over event detected:', target);
      if (target.classList.contains('images')) {
        // 获取元素的 data-index 属性值
        const index = target.getAttribute('data-a');
        // console.log('Mouse over element:', target);
        // console.log('Index of the slide:', index);
        // 更新 hoverImage 状态
        this.hoverImage = parseInt(index, 10);
        this.$forceUpdate();

        // 处理 mouseover 事件的逻辑
      }
      // 处理 mouseover 事件的逻辑
    },
    handleMouseLeave() {
      // 鼠标离开时重置 hoverImage 状态
      this.hoverImage = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.three {
  width: 100vw;
  height: 100vh;
  padding-top: 15vh;
  position: relative;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    display: inline-block;
    font-size: 42.33px;
    font-weight: 400;
    letter-spacing: 6px;
    font-family: typeface !important;
    background: linear-gradient(90deg,
        rgba(96, 252, 148, 1) 0%,
        rgba(77, 255, 201, 1) 6.02%,
        rgba(0, 204, 255, 1) 74.71%,
        rgba(71, 175, 255, 1) 100%);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    vertical-align: top;
  }

  img {
    width: 340px;
  }
}

.list {
  // margin-top: 10vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 18vh;

  .swiper {
    display: flex;
    align-items: center;

    .btn {
      width: 3vw;
      height: 2vw;
      // margin-right: 20px;
    }

    .swiper-button-prev {
      margin-left: -6vw;
    }

    .swiper-button-next {
      margin-right: -6vw;

    }
  }
}

.swiper-container-three {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  // display: flex;
  // align-items: flex-end;
  /* 对齐图片底部 */
}

.swiper-slide {
  height: 100%;
  box-sizing: border-box;

  .images {
    transition: 300ms;


    height: 80%;
    opacity: 0.5;
  }

}

.swiper-slide .swiper-slide-active {
  .images {
    transition: 300ms;
    height: 100%;
    opacity: 1
  }

}

.swiper-slide .swiper-slide-prev,
.swiper-slide .swiper-slide-next {
  .images {
    transition: 300ms;

    height: 90%;
    opacity: 0.75;
  }

}

.btn {
  margin-top: 3vh;
  display: flex;
  justify-content: space-between;
  width: 7vw;
}



.btn:hover {
  cursor: pointer;
}

.swiper-button-next,
.swiper-button-prev {
  background: transparent;
}

.tips {
  height: 18px;
  p {
    font-family: 'PingFang';
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28.96px;
    color: rgba(148, 148, 148, 1);
    margin-top: 2px;
  }

}
</style>