<template>
<div class="one">
 <div class="center">
  <img src="https://gw-resources.uhan.net.cn/person.png" alt="">
 
 </div>
</div>
  </template>
  
  <script>
  export default {
    name: "moduleOne",
    data() {
      return {
      
      };
    },
    computed: {
    },
  
    created() {
  
    },
    mounted() {
   
    },
    methods: {

    },
  };
  </script>
  
  <style lang="scss" scoped>
.one{
  width: 100vw;
  height: 100vh;
  // background: url('../assets/img/black.png') no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  .center{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  height: 100%;

    box-sizing: border-box;
    img{
      width: 100%;
    }
  }
}
  </style>

  