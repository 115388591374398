import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseData: {},
    light:'black',
    slideTo:null,
    slideIndex:0,
  },
  mutations: {
    SET_BASE_DATA: (state, baseData) => {
      state.baseData = baseData
    },
    setLight(state, payload) {
      state.light = payload;
    },
    setSlide(state, payload) {
      state.slideTo = payload;
    },
    setIndex(state, payload) {
      state.slideIndex = payload;
    },
  },
  getters: {
    baseData: state => state.baseData,
  },
  actions: {
  },
  modules: {
  }
})
