<template>
  <div>
    <!-- 导航栏 -->
    <div v-scroll-hide class="group_2" :class="{'special': $store.state.slideIndex == 8}">
      <div class="container nav">
        <div class="image_1">
          <!-- <img :src="$myVariable + '优航logo [转换].png'" /> -->
          <img @click="go" :src="baseData.officialWebsiteIcon" />
        </div>
        <ul>
          <div v-for="(item,index) in navMenuList" :key="item.to">
            <li style="cursor: pointer" v-if="item.show === 0" @click="handleTabClick(item,index)" :class="{
            'text_44': $route.meta.light === item.to,
            'white': $store.state.light == 'white'
          }">
              {{ item.columnName }}
            </li>
          </div>
        </ul>
      </div>
    </div>
    <!-- <div style="height: 80px"></div> -->
    <!-- 返回顶部按钮 -->
    <!-- <img v-show="btn" src="./assets/img/btn.png"
      style="position: fixed; right: 2vw; top: 90%; width: 58px; height: 58px;z-index: 100;" @click="above"></img> -->
    <!-- 路由页面 -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <!-- 底部栏 -->
    <!-- <footerdibu @change="locationbtn($event)"></footerdibu> -->
  </div>
</template>

<script>
import Footerdibu from "./components/footerdibu.vue";
export default {
  name: "app",
  components: { Footerdibu },
  data() {
    return {
      top: "",
      // 返回顶部按钮是否显示
      btn: false,
      // 导航栏列表
      navMenuList: [],
    };
  },
  computed: {
    baseData() {
      return this.$store.getters.baseData;
    },
    light() {
      return this.$store.state.light;
    },
    index(){
      return this.$store.state.slideIndex;

    }
   

  },
  watch: {
    light(newValue) {
      console.log('newValue', newValue);
      if (newValue == 'white') {
        this.btn = true;
      } else {
        this.btn = false;

      }
    },
    // index(newValue){
    //   if (newValue == 0) {
    //     this.btn = true;
    //   }
    // }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    const routes = [
      "",
      "home",
      "human",
      "special",
      "solution",
      "case",
      "about",
      "news",
    ];
    // 获取导航栏数据
    this.$axios.get("/column/list").then((response) => {
      this.navMenuList = response.data.rows
        .map((item) => ({ ...item, to: routes[item.id] }))
        .sort((a, b) => a.sort - b.sort);
    });
    // 获取基础信息数据并存到 vuex 中
    this.$axios.get("/content/getContent").then((response) => {
      this.$store.commit("SET_BASE_DATA", response.data.data);
    });
  },
  methods: {
    go() {
      window.location.reload();
    },
    // 页面跳转
    handleTabClick(item,index) {
      console.log('item',);
      // if (item.columnName == '数智人') {
      this.$store.commit('setSlide', index);
      // }
      if (item.columnName == '解决方案' || item.columnName == '新闻资讯') {
        this.$store.commit('setLight', 'white');
      } else {
        this.$store.commit('setLight', 'black');

      }
      const routes = [
        "",
        "/",
        "/human/0/0",
        "/special/0",
        "/solution/0/0",
        "/case/0",
        "/aboutus/0",
        "/information/0",
      ];
      if (item.targetWindow === 0) {
        this.$router.push({
          name: item.to,
          params: { id: 0, top: 0, val: "1" },
        });
      } else {
        window.open("http://192.168.10.35:8080" + routes[item.id], "_blank");
      }
    },
    above() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    // 往上滚动时，显示导航栏
    handleScroll() {
      let currentScrollPos = window.pageYOffset;
      if (currentScrollPos < 400) {
        this.btn = false;
      } else {
        this.btn = true;
      }
    },
    // 点击底部栏滚动对应位置
    locationbtn(val) {
      window.scrollTo({ top: val });
    },
  },
  directives: {
    scrollHide: {
      inserted(el) {
        let prevScrollPos = window.pageYOffset;
        window.addEventListener("scroll", () => {
          const currentScrollPos = window.pageYOffset;

          if (currentScrollPos <= 80) {
            // 页面位置小于 80
            el.style.top = "0";
          } else {
            if (prevScrollPos < currentScrollPos) {
              // 向下滚动
              el.style.top = "-80px";
            } else {
              el.style.top = "0";
            }
          }

          prevScrollPos = currentScrollPos;
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
li:hover {
  // width:82px;
  // border-bottom: 3px solid rgb(26, 101, 255);
  // color: rgb(34, 106, 255);
}

.group_2 {
  // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnged6dc1e4e17367cd22181277eb2b2e60c3bb6332f9384c0ad6a4208b9e701856)
  // background: linear-gradient(to left, #f6f9fe, #eaeef7);
  // repeat center;
  //
  background: transparent;

  position: fixed;
  z-index: 999;
  right: 0;
  left: 0;
  transition: all 0.2s linear;

  .nav {
    height: 72px;
    display: flex;
    line-height: 72px;
    justify-content: space-between;

    ul {
      list-style: none;
      display: flex;
      margin-left: 5vw;

      li {
        font-family: 'SiYuan';
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        margin: 0 0 0 100px;
        height: 77px;
        text-align: center;
        text-wrap: nowrap;
      }

      .white {
        color: black;

      }
    }

    .image_1 {
      width: 143px;
      height: 36px;
      cursor: pointer;
      img {
        width: 114.83px;
        height: 32px;
        vertical-align: middle;
      }
    }
  }

  .text_44 {
    font-family: 'SiYuan';
    font-weight: 600;
    background: linear-gradient(90deg,
        rgba(96, 252, 148, 1) 0%,
        rgba(77, 255, 201, 1) 6.02%,
        rgba(0, 204, 255, 1) 74.71%,
        rgba(71, 175, 255, 1) 100%);
    -webkit-background-clip: text;
    color: transparent !important;
  }
}
.group_2.special{
  background: rgba(0, 0, 0, 0.25);

}
</style>
