<template>
  <div class="three">
    <!-- <div class="title">
      <p class="text">桌面式产品全家展示</p>
      <img src="../assets/img/cross.png" alt="" class="cross">
    </div> -->
    <div class="human">
      <img src="https://gw-resources.uhan.net.cn/digtal.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: "moduleThree",
  data() {
    return {

    };
  },
  computed: {
  },

  created() {
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.three {
  width: 100%;

  .human {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }
  }

}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    display: inline-block;
    font-size: 42.33px;
    font-weight: 400;
    letter-spacing: 6px;
    font-family: typeface !important;
    background: linear-gradient(90deg,
        rgba(96, 252, 148, 1) 0%,
        rgba(77, 255, 201, 1) 6.02%,
        rgba(0, 204, 255, 1) 74.71%,
        rgba(71, 175, 255, 1) 100%);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    vertical-align: top;
  }

  img {
    width: 340px;
  }
}
</style>