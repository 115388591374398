<template>
  <div>
    <div class="swiper-container" style="width: 100%; height: 100vh;">
      <!-- 背景图像的容器 -->
      <div :style="{ backgroundImage: `url(${backgroundImage})` }" class="parallax-bg">
      </div>
      <div class="swiper-wrapper">
        <div class="swiper-slide  slide1">
          <div data-swiper-parallax-y="-5000">
            <one />
          </div>
        </div>
        <div class="swiper-slide  slide2">
          <div data-swiper-parallax="-5000">
            <two />
          </div>
        </div>
        <div class="swiper-slide  slide3">
          <div data-swiper-parallax="-5000">
            <three @update="handleUpdate" />
          </div>
        </div>
        <div class="swiper-slide  slide4">
          <div data-swiper-parallax="-5000">
            <four />
          </div>
        </div>
        <div class="swiper-slide  slide5">
          <div data-swiper-parallax="-5000">
            <five />
          </div>
        </div>
        <div class="swiper-slide  slide6">
          <div data-swiper-parallax="-5000">
            <six />
          </div>
        </div>
        <div class="swiper-slide  slide7">
          <div data-swiper-parallax="-5000">
            <seven />
          </div>
        </div>
        <div class="swiper-slide  slide8">
          <div data-swiper-parallax="-5000">
            <eight />
          </div>
        </div>
        <div class="swiper-slide  slide9">
          <div data-swiper-parallax="-5000">
            <nine />
          </div>
        </div>
        <div class="swiper-slide  slide10">
          <div data-swiper-parallax="-5000">
            <ten />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import vueSeamlessScroll from "vue-seamless-scroll";
import one from "../components/moduleOne.vue"
import two from "../components/moduleTwo.vue"
import three from "../components/moduleThree.vue"
import four from "../components/moduleFour.vue"
import five from "../components/moduleFive.vue"
import six from "../components/moduleSix.vue"
import seven from "../components/moduleSeven.vue"
import eight from "../components/moduleEight.vue"
import nine from "../components/moduleNine.vue"
import ten from "../components/moduleTen.vue"
import swiper from 'swiper/dist/js/swiper';
export default {
  name: "HomeView",
  components: {
    vueSeamlessScroll,
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
  },
  data() {
    return {
      // loopData0: [
      //   {
      //     lanhuimage0: require("@/assets/首页/核心优势/11.png"),
      //     lanhutext0: "方案设计",
      //     lanhutext1: "专业团队为您设计优质方案",
      //   },
      //   {
      //     lanhuimage0: require("@/assets/首页/核心优势/22.png"),
      //     lanhutext0: "专业团队",
      //     lanhutext1: "专业团队为您设计优质方案",
      //   },
      //   {
      //     lanhuimage0: require("@/assets/首页/核心优势/33.png"),
      //     lanhutext0: "软件定制",
      //     lanhutext1: "专业团队为您设计优质方案",
      //   },
      //   {
      //     lanhuimage0: require("@/assets/首页/核心优势/44.png"),
      //     lanhutext0: "集成平台",
      //     lanhutext1: "专业团队为您设计优质方案",
      //   },
      //   {
      //     lanhuimage0: require("@/assets/首页/核心优势/55.png"),
      //     lanhutext0: "项目实施",
      //     lanhutext1: "专业团队为您设计优质方案",
      //   },
      //   {
      //     lanhuimage0: require("@/assets/首页/核心优势/66.png"),
      //     lanhutext0: "售后服务",
      //     lanhutext1: "专业团队为您设计优质方案",
      //   },
      // ],

      link: "jr",

      listData: [1, 2, 3, 4, 5, 6],
      classOption: {
        limitMoveNum: 2,
        direction: 2,
      },

      product: "gymp",

      // 接口数据
      pageData: {},
      mySwiper: '',
      slide1: 'https://gw-resources.uhan.net.cn/black.png',
      slide2: 'https://gw-resources.uhan.net.cn/black.png',
      slide3: 'https://gw-resources.uhan.net.cn/four.png',
      slide4: 'https://gw-resources.uhan.net.cn/four.png',
      slide5: 'https://gw-resources.uhan.net.cn/four.png',
      slide6: 'https://gw-resources.uhan.net.cn/three.png',
      slide7: 'https://gw-resources.uhan.net.cn/seven.png',
      slide8: 'https://gw-resources.uhan.net.cn/eight.png',
      slide9: 'https://gw-resources.uhan.net.cn/nine.png',
      slide10: 'https://gw-resources.uhan.net.cn/ten.png',
      backgroundImage: 'https://gw-resources.uhan.net.cn/black.png',
      activeIndex: 0,
    };
  },
  computed: {
    slideNum() {
      return this.$store.state.slideTo;
    },
    optionSwitch: {
      get() {
        return {
          navigation: true, //左右方向的滚动是否显示控制器按钮
          switchSingleStep: 450, //点击切换移动的 step 值(px)
          direction: 3, //方向: 0 往下 1 往上 2 向左 3 向右。
          switchOffset: 10, //左右切换按钮距离左右边界的边距(px)。
        };
      },
    },
    // 合作伙伴列表
    partnerList() {
      return this.$store.getters.baseData.partnerInformation?.split(",").filter(item => item !== '')
    },
  },
  watch: {
    slideNum(newValue) {
      if (newValue == 0) {
        console.log('slideTo', newValue);
        this.$store.state.slideTo = null;
        this.mySwiper.slideTo(0, 1000, false); // 移动到第一页（索引0），动画时间为1000ms
        // 重置允许滑动的状态
        this.mySwiper.allowSlideNext = true;
        this.mySwiper.allowSlidePrev = true;
        //  // 销毁并重启 Swiper
        //  this.resetSwiper();
        this.intSwiper()
      }
    }
  },
  created() {
    console.log('--------', this.slide111);  // 应该打印出图片路径
    this.getList();

  },
  mounted() {
    this.intSwiper()
    // window.scrollTo({ top: 0 });

  },
  destroyed() {

    console.log('--------destroyed')
  },
  methods: {
    resetSwiper() {
      if (this.mySwiper) {
        this.mySwiper.destroy(true, true); // 销毁 Swiper 实例
      }
      this.intSwiper(); // 重新初始化 Swiper
    },
    bindSwiperEvents() {
      // 重新绑定 mousewheel 滑动事件等
      this.mySwiper.on('slideChange', this.onSlideChange);
      this.mySwiper.mousewheel.enable();
    },
    intSwiper() {
      this.mySwiper = new Swiper(".swiper-container", {
        direction: 'vertical',
        speed: 1000,
        parallax: true,
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
        simulateTouch: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          slideChange: this.onSlideChange,
        }
      });
    },
    async getList() {
      this.$axios.get("/settings/homePage").then((response) => {
        this.pageData = response.data.data;
      });
    },
    onSlideChange() {
      // 获取当前活动页面的索引
      this.activeIndex = this.mySwiper.activeIndex;
      this.$store.commit('setIndex', this.activeIndex);
      console.log('当前激活的页面索引是:', this.activeIndex);
      // this.mySwiper.allowSlideNext = true;
      // this.mySwiper.allowSlidePrev = true;
      const slideProperty = `slide${this.activeIndex + 1}`;
      // console.log('当前 slideProperty:', slideProperty, this.slide1,); // 打印 slideProperty
      // console.log(require('../assets/img/flow.png'));
      // console.log('对应的图片路径:', this[slideProperty]); // 打印对应的图片路径
      this.backgroundImage = this[slideProperty]
      if (this.activeIndex == 9) {
        this.$store.commit('setLight', 'white');
        console.log(this.$store.state.light);
      } else {
        this.$store.commit('setLight', 'black');
        console.log(this.$store.state.light);

      }
      // 当滑动到 three 时，暂停外部 Swiper 的滑动
      // if (activeIndex === 2) { // 这里的 2 对应 slide3，即 <three /> 所在的 slide
      //   this.mySwiper.allowSlideNext = false;
      //   this.mySwiper.allowSlidePrev = false;
      // } else {
      //   // 在其他滑块时，允许外部 Swiper 正常滑动
      //   this.mySwiper.allowSlideNext = true;
      //   this.mySwiper.allowSlidePrev = true;
      // }
    },
    handleUpdate(message) {
      // console.log('message', message);
      // if (message == 'up') {
      //   this.mySwiper.allowSlidePrev = true;
      //   this.mySwiper.slidePrev();
      // } else if (message == 'down') {
      //   this.mySwiper.allowSlideNext = true;
      //   this.mySwiper.slideNext();
      // }


    },
    handleMouseEnter(index) {
      this.activeIndex = index; // Change activeIndex when mouse enters an <li>
      this.$refs.carousel.setActiveItem(index);
    },
    handleCarouselChange(i) {
      this.activeIndex = i;
    },
    reset() {
      window.scrollTo({ top: 850 });
    },
    above() {
      window.scrollTo({ top: 0 });
    },
    appreciationDetails() { },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height: 0 !important;
  padding-bottom: 40%;
}

.ssb {
  li {
    transition: background-color 0.3s;
  }
}

li.active {
  width: 222px !important;
  // height: 100%;
  // line-height: 100px !important;
  color: #fff !important;
  background: url(../assets/微信图片_20230815111838.jpg) no-repeat !important;
  /* 设置上半部分和下半部分的颜色和分界位置 */
  background-origin: padding-box;
  animation: myfirst 0.5s 1 forwards !important;
  /* 设置背景图像相对于内容框的位置 */
}

a:visited {
  color: #1a6aff;
}

@keyframes myfirst {
  0% {
    line-height: 60px;
  }

  100% {
    line-height: 100px;
  }
}
</style>

<style lang="scss" scoped>
::v-deep .box_2 {
  .el-carousel__item--card {
    width: 80% !important;
    transition: transform 1.3s ease-in-out !important;
  }

  .el-carousel__item,
  .el-carousel__mask {
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: -15% !important;
  }
}

html,
body {
  position: relative;
  height: 100%;
}

.parallax-bg {
  // background: pink;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  z-index: -1;
  transition: background-image 0.5s ease-in-out;
  // transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  /* 确保背景在内容后面 */
}

/* 全局背景设置，背景图不滚动 */
.swiper-container {
  overflow: hidden;
}

/* 每个滑块的背景 */
.swiper-slide {
  // position: fixed;
  // top: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

// .slide1 {
//   background-image: url('../assets/img/black.png');
// }

// .slide2 {
//   background-image: url('../assets/img/flow.png');
// }

// .slide3 {
//   background-image: url('../assets/img/four.png');
// }

// .slide4 {
//   background-image: url('../assets/img/four.png');
// }

// .slide5 {
//   background-image: url('../assets/img/four.png');
// }

// .slide6 {
//   background-image: url('../assets/img/three.png');
// }

// .slide7 {
//   background-image: url('../assets/img/seven.png');
// }

// .slide8 {
//   background-image: url('../assets/img/eight.png');
// }

// .slide9 {
//   background-image: url('../assets/img/five.png');
// }

// .slide10 {
//   background-image: url('../assets/img/ten.png');
// }

/* 背景图的覆盖效果 */
.swiper-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  transition: opacity 0.6s ease;
  opacity: 1;
}

.swiper-slide-active::before {
  opacity: 1;
}
</style>
