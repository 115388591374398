<template>
  <div class="nine">
  <img class="about" src="https://gw-resources.uhan.net.cn/about.png" alt="">
  </div>
    </template>
    
    <script>
    export default {
      name: "moduleNine",
      data() {
        return {
        
        };
      },
      computed: {
      },
    
      created() {
    
      },
      mounted() {
     
      },
      methods: {
  
      },
    };
    </script>
    
    <style lang="scss" scoped>
  .nine{
    position: relative;
    width: 100vw;
    height: 100vh;
  
  .about{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    // cursor: pointer;
    // width: 70%;
        // scale: 0.6;

   
  }
  }
    </style>
  
    