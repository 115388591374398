<template>
  <div class="two">
    <div class="center">
      <img src="https://gw-resources.uhan.net.cn/arithmetic.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "moduleTwo",
  data() {
    return {

    };
  },
  computed: {
  },

  created() {

  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.two {
  width: 100vw;
  height: 100vh;
  padding-top: 7%;
  background-size: cover;
  position: relative;
  overflow: hidden;

  .center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    box-sizing: border-box;

    img {
      width: 100%;
      // scale: 0.9;
    }
  }
}
</style>