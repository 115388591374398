import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/script/flexible.js'
import '@/assets/style/index.scss'
// import Router from 'vue-router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css' //黑色主题，会好看一点
import axios from 'axios'
import './assets/css/font/index.css'
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers['isPreview'] = process.env.VUE_APP_PREV_FLAG

// 图片全缀
Vue.prototype.$myVariable = "https://gw-resources.uhan.net.cn/tem/"

import scroll from 'vue-seamless-scroll'
// const originalPush = Router.prototype.push

Vue.prototype.$axios = axios
Vue.use(scroll)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})
// Router.prototype.push = function push(location) { 

//   return originalPush.call(this, location).catch(err => err)
// }
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
