<template>
  <div class="ten">
    <div class="title">
      <p class="text">合作客户</p>
    </div>
    <div class="friends">
      <vue-seamless-scroll :data="imgList" :class-option="classOption" class="warp">
        <ul class="ul-item" :style="{ width: 222 * Math.ceil(imgList.length) + 'px' }">
          <li class="li-item" v-for="(item, index) in imgList" :key="index">
            <img style="height: 100%; width: 200px; border-radius: 12px;height:80px" :src="item" />
          </li>
        </ul>
      </vue-seamless-scroll>
      <vue-seamless-scroll :data="imgList" :class-option="classOption1" class="warp">
        <ul class="ul-item" :style="{ width: 222 * Math.ceil(imgList.length) + 'px' }">
          <li class="li-item" v-for="(item, index) in imgList" :key="index">
            <img style="height: 100%; width: 200px; border-radius: 12px;height:80px" :src="item" />
          </li>
        </ul>
      </vue-seamless-scroll>
      <vue-seamless-scroll :data="imgList" :class-option="classOption" class="warp">
        <ul class="ul-item" :style="{ width: 222 * Math.ceil(imgList.length) + 'px' }">
          <li class="li-item" v-for="(item, index) in imgList" :key="index">
            <img style="height: 100%; width: 200px; border-radius: 12px;height:80px" :src="item" />
          </li>
        </ul>
      </vue-seamless-scroll>
      <div class="blur-left"></div>
      <div class="blur-right"></div>
    </div>
    <div class="bottom">
      <div class="left">
        <p class="contact">联系我们</p>
        <div class="list" v-for="(item, index) in contact" :key="index">
          <span class="normal"> {{ item.split('：')[0] }}：</span> <span class="normal special">{{ item.split('：')[1]
            }}</span>
        </div>

      </div>
      <div class="middle" @click="handleLink">
        {{ filingInformation }}
      </div>
      <div class="right">
        <div class="weChat">
          <img :src="channel[0].qrCode" alt="" />
          <span class="text">扫一扫关注公众号</span>
        </div>
        <div class="trill">
          <img :src="channel[1].qrCode" alt="" />
          <span class="text">扫一扫关注抖音</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "moduleTen",
  components: { vueSeamlessScroll },
  data() {
    return {

      imgList: [],// 合作伙伴轮播图
      contact: [], //联系我们
      filingInformation: '',//申请信息
      channel: [], // 平台信息
      classOption: {
        limitMoveNum: 2,
        direction: 2,
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 3, // 0向下 1向上 2向左 3向右
      },
      classOption1: {
        limitMoveNum: 2,
        direction: 2,
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
      },
    };
  },
  computed: {
  },

  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    async getList() {
      try {
        const response = await this.$axios.get("/content/getContent")
        this.imgList = response.data.data.partnerInformation.split(',')
        this.contact = response.data.data.contact.split(',')
        this.filingInformation = response.data.data.filingInformation
        this.channel = response.data.data.channel
        console.log(this.contact);

      } catch (error) {
        console.error("请求错误:", error)
      }
    },
    handleLink() {
      window.open('https://beian.miit.gov.cn', '_blank')
    },
  },
};
</script>

<style lang="scss" scoped>
.ten {
  width: 100vw;
  height: 100vh;
  padding-top: 12vh;
  position: relative;

}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;

  .text {
    display: inline-block;
    font-size: 42.33px;
    font-weight: 400;
    letter-spacing: 6px;
    font-family: typeface !important;
    background: linear-gradient(90deg, rgba(45, 234, 237, 1) 0%, rgba(0, 204, 255, 1) 49.2%, rgba(71, 175, 255, 1) 100%);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    vertical-align: top;
  }

}

.friends {
  width: 90%;
  height: 43vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  .warp {
    width: 100%;


    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;

      &.ul-item {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        .li-item {
          margin-bottom: 2vh;
          width: 200px;
          height: 109px;
          margin-right: 22px;
          line-height: 80px;
          color: #fff;
          text-align: center;
          font-size: 30px;
        }
      }
    }
  }
}

.bottom {
  height: 20vh;
  color: #fff;
  margin-top: 12vh;
  // top: 76vh;
  padding: 0 12vw;
  // position: absolute;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .left {
    width: 40vh;
    font-size: 1.4vh;
    text-align: left;
    vertical-align: top;
    line-height: 2vh;

    .contact {
      font-family: 'PINGFANG';
      font-weight: 500;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 3vh;

    }

    .list {
      font-weight: 400;
      color: rgba(134, 140, 143, 1);

      .normal {
        display: inline-block;
        margin-bottom: 1.5vh;
         font-family: 'PINGFANG';
      }

      .special {
        color: rgba(235, 243, 255, 1);
      }
    }
  }

  .middle {
    font-family: 'PINGFANG';
    font-size: 1.4vh;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 18.2px;
    color: rgba(134, 140, 143, 1);
    text-align: left;
    vertical-align: top;
  }

  .right {
    display: flex;

    .weChat,
    .trill {
      width: 15vh;
      margin-left: 36px;

      img {
        width: 15vh;
        height: 15vh;
        margin-bottom: 26px;
        border-radius: 10px;
      }

      .text {
        display: inline-block;
        width: 100%;
        font-family: 'PINGFANG';
        font-size: 1.4vh;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18.2px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        vertical-align: top;
      }
    }
  }
}
</style>