<template>
  <div class="footer">
    <div>
      <div class="container">
        <ul style="">
          <li class="fontsz">
            <a style="">解决方案</a>
          </li>
          <!-- <router-link to="/about/jjfa/jr/0">
            <li @click="jjfnbrn('jr')">数智金融</li>
          </router-link>

          <router-link to="/about/jjfa/xc/0">
            <li @click="jjfnbrn('xc')">数智乡村</li>
          </router-link>

          <router-link to="/about/jjfa/wl/0">
            <li @click="jjfnbrn('wl')">智慧文旅</li>
          </router-link>

          <router-link to="/about/jjfa/zw/0">
            <li @click="jjfnbrn('zw')">保安行业数字化</li>
          </router-link> -->
          <!-- <router-link v-for="item in baseData.solutionList" :key="item.id" :to="'/about/jjfa/' + item.id + '/0'"> -->
            <li
              style="cursor: pointer;"
              v-for="item in baseData.solutionList" :key="item.id"
              @click="
                jjfnbrn('wl');
                $router.push({ name: 'solution', params: { id: item.id, top: 0, val: '1' } })
            ">
              {{ item.schemeName }}
            </li>
          <!-- </router-link> -->
          <li
            style="color: #7eafff; cursor: pointer;"
            v-if="baseData.solutionShow === 0"
            @click="$router.push({ name: 'solution', params: { id: 0, top: 0, val: '1' } })"
          >了解更多>></li>
        </ul>

        <ul style="">
          <li class="fontsz">
            <a>案例赏析</a>
          </li>

          <!-- <router-link :to="{ name: 'analysissub', params: { val: 'gx' } }">
            <li @click="bankbtn('gx')">农行某分行</li>
          </router-link>
          <router-link :to="{ name: 'analysissub', params: { val: 'zs' } }">
            <li @click="bankbtn('zs')">农行某支行</li>
          </router-link>
          <router-link :to="{ name: 'analysissub', params: { val: 'fd' } }">
            <li @click="bankbtn('fd')">凤都村党群服务中心</li>
          </router-link> -->
          <li
            v-for="item in baseData.uhanCaseAnalysisList" :key="item.id"
            @click="$router.push({ name: 'analysissubcase', params: { id: item.id, top: 0, val: '1' } })"
            style="cursor: pointer;"
          >{{ item.caseName }}</li>
          <li
            @click="$router.push({ name: 'case', params: { id: 0, top: 0, val: '1' } }) "
            v-if="baseData.caseAnalysisShow === 0"
            style="color: #7eafff; cursor: pointer;"
          >了解更多>></li>
        </ul>

        <ul style="">
          <li class="fontsz">
            <a style="">关于我们</a>
          </li>
          <router-link to="/aboutus/600">
            <li @click="locationbtn('600')">公司简介</li>
          </router-link>
          <router-link to="/aboutus/2800">
            <li @click="locationbtn('2800')">公司荣誉</li>
          </router-link>
          <router-link to="/aboutus/4400">
            <li @click="locationbtn('4400')">公司地址</li>
          </router-link>
          <router-link to="/aboutus/4400">
            <li @click="locationbtn('4400')">联系我们</li>
          </router-link>
          <router-link to="/analysis/alsx" v-if="baseData.contactShow === 0">
            <li style="color: #7eafff">了解更多>></li>
          </router-link>
        </ul>

        <ul v-if="baseData.contact">
          <li class="fontsz">
            <a>联系我们</a>
          </li>
          <li v-for="(item, i) in baseData.contact.split(',')" :key="i">
            <a style="cursor: text">{{ item }}</a>
          </li>
        </ul>

        <ul>
          <li class="fontsz" style="">关注我们</li>
          <li style="width: 174px; height: 174px">
            <!-- <img
              style="width: 100%"
              src="../assets/微信图片_20230817094234.jpg"
            /> -->
            <ul
              class="ewmtp"
              style="
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
              "
            >
              <li
                v-for="(item, i) in baseData.channel"
                style="width: 35px; height: 35px; margin: 3px; cursor: pointer"
                :key="i"
              >
                <el-popover
                  placement="top"
                  trigger="hover"
                >
                  <img slot="reference" :src="item.icon" style="width: 100%; height: 100%" />
                  <img
                    style="width: 174px; height: 174px"
                    :src="item.qrCode"
                  />
                </el-popover>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <p
        style="
          text-align: center;
          margin-top: 140px;
          color: #f7faff;
          font-size: 16px;
        "
      >
        ©2023杭州优航信息技术有限公司 版权所有 <span style="cursor: pointer;"
          @click="handleLink">浙ICP备18022803号-2</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterColumn',
  data() {
    return {
      value: "",
    };
  },
  computed: {
    baseData() {
      return this.$store.getters.baseData
    }
  },
  created() {},
  methods: {
    bankbtn(i) {
      this.$emit("change", i);
       window.scrollTo({ top: 0 });
    },
    locationbtn(i) {
      this.$emit("change", i);
       window.scrollTo({ top: 0 });
    },
    jjfnbrn(i) {
      this.$emit("change", i);
      window.scrollTo({ top: 680 });
    },
    handleLink () {
      window.open('https://beian.miit.gov.cn', '_blank')
    },
  },
};
</script>

<style lang="scss" scoped>
.ewmtp {
  ul {
    display: flex;
    margin: 5px;
  }
}
</style>
